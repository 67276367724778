import { useCallback } from 'react';
import { NavLink } from 'react-router-dom';

import { useUniversalActions } from '@hooks/useUniversalActions/useUniversalActions';

import Toggle from '@components/molecules/Toggle/Toggle';
import LogoIcon from '@components/atoms/LogoIcon/LogoIcon';

const Header = () => {

    const { toggleMenu } = useUniversalActions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const toggle = useCallback(() => toggleMenu(), []);

    return (
        <header className="header" id="header">
            <NavLink to='/' className='header__logo'>
                <LogoIcon />
                <span className='sr-only'>go to the homepage</span>
            </NavLink>
            <Toggle onClick={toggle} />
            <div className='header__zone js-hidden-zone'></div>
        </header>
    )
}


export default Header; 
import { useEffect, useRef, memo } from 'react';
import mapboxgl from 'mapbox-gl';
import { gsap } from 'gsap';

import { useMap } from '@hooks/useMap/useMap';
import { useMapMethods } from '@hooks/useMapMethods/useMapMethods';
import { useMapData } from '@hooks/useMapData/useMapData';
import { useUniversalActions } from '@hooks/useUniversalActions/useUniversalActions';

import { IMapProps } from './Map.interfaces';

import 'mapbox-gl/dist/mapbox-gl.css';



const Map = ({ lng = -100, lat = 40, zoom = 3.5 }: IMapProps) => {


    const timelineRef = useRef(gsap.timeline({ repeat: -1, paused: true }));
    const animationRef = useRef({ value: 0 });
    const markerRef = useRef<mapboxgl.Marker | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const { setAppLoaded, closeMenu } = useUniversalActions();

    const { 
            loaded,
            sortedData, 
            isExtended, 
            current,
            points,
            rangeValue,
            isMarkerVisible,
            autoplay } = useMapData();


    const { mapRef, dataRef } = useMap({ 
                                        containerRef, 
                                        options: { lng, lat, zoom },
                                        onLoad: () => setAppLoaded()
                                    });


    const mapMethodProps = { mapRef, sortedData, markerRef, timelineRef, animationRef, data: dataRef.current };
    

    const {
            applyCurrent, 
            applyTimeline,
            applyClick,
            applyHeatmap,
            applyAutoplay,
            updateHeatmap,
            updateCurrent,
            updatePopupVolume,
            removeMarker,
            removeHeatmap } = useMapMethods(mapMethodProps);



    const onClick = (event: mapboxgl.MapMouseEvent) => {
        const isMenuOpen = document.body.classList.contains('is-menu-open');
        isMenuOpen ? closeMenu() : applyClick(event);
    }



    /**
     *  Update heatmap with period's change
     */
    useEffect(() => {
        
        if (!loaded || !isExtended) {
            return;
        }

        updateHeatmap(current.period);
        updateCurrent(current.period);
        isMarkerVisible && updatePopupVolume(points);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current.period, isExtended, loaded]);


    /**
     *  Handle heatmap when map is extended
     */
    useEffect(() => {

        const map = mapRef.current;

        if (!loaded || !isExtended) {
            removeHeatmap();
            removeMarker();
            map?.off('click', applyClick);
            return;
        }
        
        // applyPopup();
        applyHeatmap();
        applyTimeline();
        applyCurrent();

        map?.on('click', onClick);
        map?.on('move', () => removeMarker(true));
        map?.on('zoom', () => removeMarker(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded, isExtended]);


    /**
     *  Handle Autoplay
     */
    useEffect(() => {
        
        isExtended && applyAutoplay({ timelineRef, autoplay, rangeValue });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoplay]);



    return (
        <div className='map' id='nuclear-map'>
            <div ref={containerRef} className='map__wrap'></div>
        </div>
    );
}



export default memo(Map);
import { ComponentType, useLayoutEffect } from 'react';

export function withDetection<P>(Component: ComponentType<P>) {
    const WrappedComponent = (props: P) => {


        const detectMac = () => {
            const isMac = navigator.platform.toUpperCase().indexOf('MAC') > -1;
            document.body.classList.toggle('is-mac', isMac);
        }

        const detectWindows = () => {
            const isWin = navigator.platform.toUpperCase().indexOf('win') > -1;
            document.body.classList.toggle('is-windows', isWin);
        }

        const detectTouchEvents = () => {
            const hasTouch = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
            document.body.classList.toggle('touchevents', hasTouch);
        }



        useLayoutEffect(() => {

            detectMac();
            detectWindows();
            detectTouchEvents();


        }, []);


        return (
            <Component {...props} />
        )
    }

    return WrappedComponent;
    
}
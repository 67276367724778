import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPopup, IMapItem, IPoints, IPopupValues } from '@redux/types/mapSlice';
import { IMapSlice } from "@redux/types/mapSlice";



export const initialState: IMapSlice = {
    current: {
        period: [],
        date: {
            hour: '',
            date: ''
        },
        volume: 1000,
        color: 'red',
        periodIndex: 1,
    },
    points: {
        x: 0,
        y: 0,
        lng: 0,
        lat: 0
    },
    items: [],
    isExtended: false,
    sortedData: null,
    loaded: false,
    autoplay: false,
    isMarkerVisible: false,
    isDropdownOpen: false,
    rangeValue: 0,
    popup: {
        location: '',
        volume: 0,
        color: 'red',
        changed: false
    },
};


const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        updateCurrent(state, action: PayloadAction<IMapItem>) {
            state.current = action.payload;
        },
        updateAutoplay(state, action: PayloadAction<boolean>) {
            state.autoplay = action.payload;
        },
        toggleAutoplay(state) {
            state.autoplay = !state.autoplay;
        },
        updateMarker(state, action: PayloadAction<boolean>) {
            state.isMarkerVisible = action.payload;
        },
        updatePopup(state, action: PayloadAction<IPopup>) {
            state.popup = {
                ...action.payload,
                changed: !state.popup.changed
            }
        },
        updatePoints(state, action: PayloadAction<IPoints>) {
            state.points = action.payload;
        },
        updatePopupValues(state, action: PayloadAction<IPopupValues>) {
            state.popup.volume = action.payload.volume;
            state.popup.color = action.payload.color;
        },
        updateRange(state, action: PayloadAction<number>) {
            state.rangeValue = action.payload;
        },
        updateData(state, action: PayloadAction<any>) {
            state.sortedData = action.payload;
        },
        updateMapItems(state, action: PayloadAction<IMapItem[]>) {
            state.items = action.payload;
        },
        load(state, action: PayloadAction<boolean>) {
            state.loaded = action.payload;
        },
        extend(state, action: PayloadAction<boolean>) {
            state.isExtended = action.payload;
        },
        updateDropdown(state, action: PayloadAction<boolean>) {
            state.isDropdownOpen = action.payload;
        },
        toggleDropdown(state) {
            state.isDropdownOpen = !state.isDropdownOpen;
        },
        togglePopup(state) {
            state.popup.changed = !state.popup.changed
        },
        reset(state, action: PayloadAction<IMapItem>) {
            state = {
                ...initialState,
                isDropdownOpen: false,
                current: action.payload
            }
        },
    }
});



export const {
        updatePopup, 
        updateMarker, 
        updateAutoplay, 
        updateRange,
        updateData,
        updateDropdown,
        updatePoints,
        updatePopupValues,
        updateCurrent,
        updateMapItems,
        toggleDropdown,
        toggleAutoplay,
        togglePopup,
        load,
        extend,
        reset
    } = mapSlice.actions;



export default mapSlice.reducer;
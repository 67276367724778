import { useAppDispatch } from '@redux/store';
import { mapActions } from '@redux/actions/map';

export const useMapActions = () => {
    
    const dispatch = useAppDispatch();

    const data = mapActions(dispatch);

    return data;

}
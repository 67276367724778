import mapboxgl from 'mapbox-gl';
import { group } from 'd3-array';

import { IUseMapInit, mapType } from './useMap.interfaces';



 export const formatData = (data: any) => {


    const features = [...data.features].map((f: { [key: string]: any }, index: number) => {

        const { properties } = f;
        const { begin } = properties.timespan;

        return Object.assign({}, f, { properties: { ...properties, begin, id: index } });

    });


    return {
        ...data,
        features
    };

}



export const init = ({ mapRef, containerRef, options, token, onLoad }: IUseMapInit): Promise<void> => {

    const { lng, lat, zoom } = options;

    return new Promise(resolve => {

        const container = containerRef.current! as HTMLElement;

        mapboxgl.accessToken = token;

        mapRef.current = new mapboxgl.Map({
            container,
            style: 'mapbox://styles/huncwoty/cl4fg3699000o14qv8ec5gp6v',
            center: [lng, lat],
            zoom: zoom,
            interactive: true,
            dragRotate: false,
            minZoom: zoom,
            maxZoom: zoom + 4,
            touchZoomRotate: window.innerHeight < 1024
        });

        

        mapRef.current.on('load', () => {
           onLoad();
           resolve();
        });
    });

};



export const sortData = (data: GeoJSON.FeatureCollection ) => {

    if (!data) { return null; }

    return group(data.features, ({ properties }: any) => properties!.timespan.begin);

}



 export const addHeatmap = (map: mapType, data: any): void => {

    if (!map.current) { return; }

    map.current.addSource('heatmap', {
        type: 'geojson',
        data: data as GeoJSON.FeatureCollection,
    });

    map.current.addLayer({
        id: 'heatmap-layer',
        source: 'heatmap',
        type: 'fill',
        paint: {
            'fill-color': ['case', ['has', 'fill'], ['get', 'fill'], 'rgba(0, 0, 0, 0)'],
        },
    });

};



export const removeHeatmap = (mapRef: mapType): void => {

    if (!mapRef) { return; }

    const source = mapRef.current!.getSource('heatmap');
    const layer = mapRef.current!.getLayer('heatmap-layer');

    layer && mapRef.current!.removeLayer('heatmap-layer');
    source && mapRef.current!.removeSource('heatmap');

}
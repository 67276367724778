import secrets from '@secrets';

import { fetchData } from '@utils/fetchData';

import { MapboxGlEvent } from './useMapMethods.interfaces';
import { mapType } from './useMapMethods.interfaces';
import { getDataFromFeature } from '@utils/getDataFromFeature';

import { colors } from '@components/organisms/Map/Map.colors';


export const markerHtml = '<svg xmlns="http://www.w3.org/2000/svg" width="41" height="59" viewBox="0 0 41 59" fill="none"><g opacity="0.234584" filter="url(#filter0_f_147_691)"><path d="M21.5 56C29.5081 56 36 55.1046 36 54C36 52.8954 29.5081 52 21.5 52C13.4919 52 7 52.8954 7 54C7 55.1046 13.4919 56 21.5 56Z" fill="#3F3F3F"/></g><g filter="url(#filter1_d_147_691)"><path d="M20.5 48C9.5 33.697 4 23.6345 4 17.8125C4 9.0795 11.3873 2 20.5 2C29.6127 2 37 9.0795 37 17.8125C37 23.6345 31.5 33.697 20.5 48Z" fill="black"/></g><path fill-rule="evenodd" clip-rule="evenodd" d="M21 51C9.66667 35.7642 4 25.0454 4 18.8438C4 9.5412 11.6112 2 21 2C30.3888 2 38 9.5412 38 18.8438C38 25.0454 32.3333 35.7642 21 51Z" fill="#474747"/><path d="M21 26.5C25.1421 26.5 28.5 23.1421 28.5 19C28.5 14.8579 25.1421 11.5 21 11.5C16.8579 11.5 13.5 14.8579 13.5 19C13.5 23.1421 16.8579 26.5 21 26.5Z" stroke="#DBD9D7"/><defs><filter id="filter0_f_147_691" x="5" y="50" width="33" height="8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_147_691"/></filter><filter id="filter1_d_147_691" x="0" y="0" width="41" height="54" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="2"/><feGaussianBlur stdDeviation="2"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_147_691"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_147_691" result="shape"/></filter></defs></svg>';


export const getPoints = (event: MapboxGlEvent) => ({
    x:      event.point.x,
    y:      event.point.y,
    lng:    event.lngLat.lng,
    lat:    event.lngLat.lat,
})


export const getLocation = (event: MapboxGlEvent): Promise<string> => {
    
    return new Promise(resolve => {

        const { lng, lat } = getPoints(event);

        const placeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?limit=1&access_token=${secrets.mapboxToken}`;

        fetchData(placeUrl).then(({ features }) => {
            const { place_name } = (features[0] as any) ?? { place_name: '' };
            resolve(place_name);
        })

    })

};



export const getVolume = (event: MapboxGlEvent, mapRef: mapType) => {

    const points = getPoints(event);

   // Set `bbox` as 3px reactangle area around marker point.
   const bbox = [
        [points.x - 3, points.y - 3],
        [points.x + 3, points.y + 3]
    ];

    // Find features intersecting the bounding box.
    const selectedFeatures = mapRef.current!.queryRenderedFeatures(bbox as [mapboxgl.PointLike, mapboxgl.PointLike], {
        layers: ['heatmap-layer']
    });

    const { volume } = getDataFromFeature(selectedFeatures);

    return volume;

}


export const getVolumeFromPeriod = (period: any) => {

    const { volume } = getDataFromFeature(period);
    return volume;
    
}


export const getCurrentPeriod = (index: number, sortedData: any) => {

    const periodArray = [...sortedData!.entries()].filter((el, i) => i === index)[0] ?? []

    return periodArray;
    
}


export const getColor = (volume: number): string => {

    const element = [...colors].find(({ breakpoint }) => volume >= breakpoint);
    const { color } = element!;

    return color;

}


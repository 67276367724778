import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';

import { IUniversalSlice } from '@redux/types/universalSlice';

export const useUniversalData = () => {

    const data = useSelector<RootState, IUniversalSlice>(({ universal }) => universal);

    return data;

}
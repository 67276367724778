import { AppDispatch } from '@redux/store';

import * as actions from '@redux/slices/universalSlice';

export const universalActions = (dispatch: AppDispatch) => ({

    toggleMenu: () => dispatch(actions.toggleMenu()),

    closeMenu: () => dispatch(actions.closeMenu()),

    setAppLoaded: () => dispatch(actions.setAppLoaded()),

});
import { createSlice } from '@reduxjs/toolkit';

import { IUniversalSlice } from '@redux/types/universalSlice';

const initialState: IUniversalSlice = {
    isMenuOpen: false,
    isAppLoaded: false,
};

const universalSlice = createSlice({
    name: 'universal',
    initialState,
    reducers: {
        toggleMenu(state) {
            state.isMenuOpen = !state.isMenuOpen;
        },
        closeMenu(state) {
            state.isMenuOpen = false;
        },
        setAppLoaded(state) {
            state.isAppLoaded = true;
        },
    }
});


export const { toggleMenu, closeMenu, setAppLoaded } = universalSlice.actions;
export default universalSlice.reducer;
import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

import { MotionPathPlugin, MorphSVGPlugin } from 'gsap/all';

import { ReactComponent as SVGLogotype } from '@svg/logotype.svg';

gsap.registerPlugin(MotionPathPlugin, MorphSVGPlugin);

const LogoIcon = () => {

    const ref = useRef<SVGSVGElement>(null);

    const animate = (): void => {
        const logoEl = ref.current;
        const dot = logoEl?.querySelector('circle')!;
        
        const path = logoEl?.querySelector('.js-orbit')! as SVGPathElement;

        gsap.to(dot, {
            motionPath: {
                path,
                align: path,
                alignOrigin: [0.5, 0.5],
            },
            transformOrigin: '50% 50%',
            duration: 1.5,
            ease: 'linear',
            repeat: -1,
        });
    }

    useEffect(() => {
        animate();
    }, []);

    return (
        <SVGLogotype ref={ref} />
    );
}

export default LogoIcon;
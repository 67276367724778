import { IVideo } from './Video.interfaces';

const Video = ({ src, autoPlay, ...props }: IVideo ) => {

    return (
        <video playsInline autoPlay {...props}>
            <source src={src} type='video/mp4' />
        </video >
    );
};

export default Video;
import { compose } from 'recompose';

import { withRouteChange } from './withRouteChange';
import { withLayout } from './withLayout';
import { withDetection } from './withDetection';

export const withRoot = compose(
    withRouteChange,
    withLayout,
    withDetection
);

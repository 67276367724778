import { Feature, Geometry, GeoJsonProperties } from 'geojson';


interface IGetDataFromFeatureReturnType {
    volume: number;
    color: string;
    id: string;
}


type featuresType = Feature<Geometry, GeoJsonProperties>[]


export const getDataFromFeature = (features: featuresType): IGetDataFromFeatureReturnType  => {
    
    if (features.length === 0) {
        return {
            volume: 0,
            color: '',
            id: '',
        }
    }

    const arr = features.map(
        f => {
            const t = parseFloat(f.properties!.name.replaceAll(/\s/g,'').replace('ContourLevel:','').replace('pCi/m3/m3',''));
            if (!isNaN(t)) {
                return {
                    volume: t,
                    color: f.properties!.fill,
                    id: f.properties!.id,
                }
            } else {
                return {
                    volume: 0,
                    color: '',
                    id: '',
                }
            }
    }).sort(function(a, b) {
        return b.volume - a.volume;
    });

    return arr[0];
}
export const routes = [
    {
        pathname: '/',
        name: 'Home'
    },
    {
        pathname: '/map',
        name: 'Map'
    },  
    {
        pathname: '/list',
        name: 'List'
    },
    {
        pathname: '/about',
        name: 'About'
    },
]

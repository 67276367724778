import { AppDispatch } from '@redux/store';

import * as actions from '@redux/slices/mapSlice';

import { IPopup, IMapItem, IPoints, IPopupValues } from '@redux/types/mapSlice';


export const mapActions = (dispatch: AppDispatch) => ({
    
    setSortedData: (data: any) => dispatch(actions.updateData(data)),
    
    setAutoplay: (isAutoplay: boolean) => dispatch(actions.updateAutoplay(isAutoplay)),
    
    toggleMapAutoplay: () => dispatch(actions.toggleAutoplay()),

    setRangeValue: (index: number) => dispatch(actions.updateRange(index)),
    
    setPopup: (content: IPopup) => dispatch(actions.updatePopup(content)),
    
    setPoints: (points: IPoints) => dispatch(actions.updatePoints(points)),

    setPopupValues: (values: IPopupValues) => dispatch(actions.updatePopupValues(values)),

    setMarker: (isVisible: boolean) => dispatch(actions.updateMarker(isVisible)),

    setCurrent: (item: IMapItem) => dispatch(actions.updateCurrent(item)),

    setMapItems: (items: IMapItem[]) => dispatch(actions.updateMapItems(items)),
    
    setLoad: (load: boolean) => dispatch(actions.load(load)),

    setToggleDropdown: () => dispatch(actions.toggleDropdown()),

    setDropdown: (open: boolean) => dispatch(actions.updateDropdown(open)),

    extend: (ext: boolean) => dispatch(actions.extend(ext)),
    
    // reset: () => dispatch(actions.reset())

});
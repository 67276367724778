import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { gsap } from 'gsap';

import { SplitText } from 'gsap/SplitText';
import { Draggable } from 'gsap/Draggable';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';

/**
 * HOC
 * 
 */
import { withRoot } from '@hoc/root/withRoot';

/**
 *  Pages
 */
const HomePage = lazy(() => import('@pages/HomePage'));
const MapPage = lazy(() => import('@pages/MapPage'));
const AboutPage = lazy(() => import('@pages/AboutPage'));
const ListPage = lazy(() => import('@pages/ListPage'));
const NotFoundPage = lazy(() => import('@pages/NotFoundPage'));


gsap.registerPlugin(SplitText, Draggable, CSSRulePlugin);


const Root = () => {
  document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);

  const location = useLocation();

  return (
            <>
                <Suspense fallback={null}>

                    <AnimatePresence exitBeforeEnter>

                        <Routes location={location} key={location.pathname}>

                            <Route path='*' element={<NotFoundPage />} />
                            
                            <Route path='/' element={<HomePage />} />
                            <Route path='/map' element={<MapPage />} />
                            
                            <Route path='/list' element={<ListPage />} />
                            <Route path='/about' element={<AboutPage />} />
                            
                        </Routes>

                    </AnimatePresence>

                </Suspense>

            </>
    );
};

export default withRoot(Root);

import { ComponentType } from 'react';

import Layout from '@layout/Layout';

export function withLayout<P>(Component: ComponentType<P>) {
    const WrappedComponent = (props: P) => {

        return (
            <Layout>
                <Component {...props} />
            </Layout>
        )
    }

    return WrappedComponent;
    
}
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { gsap } from 'gsap';

import { ReactComponent as SVGCorner } from '@assets/svg/corner.svg';

const Corners = () => {

    const ref = useRef<HTMLDivElement>(null);

    const location = useLocation();


    useEffect(() => {
        
        const wrapper = ref.current!;
        const corners = wrapper.querySelectorAll('.js-corner path');

        gsap.set(corners, { strokeDasharray: 25 });

    }, []);

    

    useEffect(() => {

        const wrapper = ref.current!;
        const corners = wrapper.querySelectorAll('.js-corner path');

        const pathLength = 25;

        // gsap.set(corners, { strokeDasharray: pathLength, strokeDashoffset: pathLength * -1 })

        const defaults = {
            duration: 0.7,
            ease: 'power2'
        };

        const timeline = gsap.timeline({ defaults });

        timeline
            .to(corners, { strokeDashoffset: 0 })
            .to(corners, { strokeDashoffset: pathLength * -1 })
            .set(corners, { strokeDashoffset: pathLength })
            .to(corners, { strokeDashoffset: 0 })


    }, [location.pathname]);



    return (
        <>
            <div className="corners" ref={ref}>
                <div className="corners__inner">
                    {[...Array(4)].map((_, i) => (
                        <div className='corners__corner js-corner' key={i}>
                            <SVGCorner />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Corners;
import { ReactNode } from 'react';

import Header from '@components/scaffold/Header/Header';
import Menu from '@components/scaffold/Menu/Menu';
import Corners from '@components/scaffold/Corners/Corners';
import Loader from '@components/scaffold/Loader/Loader';
import Map from '@components/organisms/Map/Map';
import SvgStore from '@components/scaffold/SvgStore/SvgStore';
interface ILayout {
    children: ReactNode;
}

const Layout = ({ children }: ILayout) => (
    <>
        <SvgStore />
        <Header />
        <Menu />
        <main id="main">
            <article id="content">
                {children}
            </article>
        </main>
        <Map />
        <Corners />
        <Loader />
    </>
);


export default Layout;
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { useDispatch } from 'react-redux';

// Slices
import universalSliceReducer from './slices/universalSlice';
import mapSlice from './slices/mapSlice';

// Services
import { mapApi } from './services/map';


/**
 *  Main Reducer
 */
const reducer = combineReducers({
    universal: universalSliceReducer,
    map: mapSlice,
    mapData: mapApi.reducer
});


export const store = configureStore({
    reducer,
    devTools: false,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(mapApi.middleware)
});


setupListeners(store.dispatch);


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
// export const useAppSelector = <T>() => useSelector<RootState, T>;

export default store;



import { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

import secrets from '@secrets';

import { useUniversalData } from '@hooks/useUniversalData/useUniversalData';

import Video from '@components/universal/Video/Video';

import ExplosionVideo from '@video/explosion.mp4';


const Loader = () => {

    const [isVisible, setIsVisible] = useState<boolean>(true);

    const loaderRef = useRef<HTMLDivElement>(null);
    const counterRef = useRef<HTMLSpanElement>(null);


    const { isAppLoaded } = useUniversalData();


    const animateOut = (el: HTMLDivElement) => {
        gsap.to(el, { opacity: 0, duration: 1, ease: 'sine.out', onComplete: () => setIsVisible(false) })
    }


    useEffect(() => {

        const counter = counterRef.current;
        const loader = loaderRef.current;

        gsap.killTweensOf(counter);

        const shortTime = secrets.production ? 3.5 : 0.2;

        gsap.to(counter, {
                duration: isAppLoaded ? shortTime : 15,
                ease: 'none',
                textContent: '100%',
                roundProps: 'textContent',
                onComplete: () => {
                    document.body.classList.add('is-loaded');
                    animateOut(loader!);
                }
        });

    }, [isAppLoaded]);

    

    return (
        <>
            {isVisible && (
                <div className="loader" ref={loaderRef}>
            
                    <div className='loader__video'>
                        <Video 
                            autoPlay
                            muted
                            loop
                            src={ExplosionVideo}
                        />
                    </div>
        
                    <div className="loader__counter">
                        Loading <span ref={counterRef}></span>
                    </div>
        
                </div>
            )}
        </>
    );
}

export default Loader;
export const c = {
    red: '#E84646',
    orange: '#FB8D14',
    gray: '#BAB8B8',
    yellow: '#FBCA14',
    green: '#92C228',
    lightgreen: '#BBDB77',
    transparent: 'transparent'
};

export const colors = [
    { breakpoint: 1000, color: c.red },
    { breakpoint: 100, color: c.orange },
    { breakpoint: 10, color: c.gray },
    { breakpoint: 1, color: c.yellow },
    { breakpoint: 0.1, color: c.green },
    { breakpoint: 0.01, color: c.lightgreen },
    { breakpoint: 0, color: c.transparent }
];
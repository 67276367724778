import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { IMapSlice } from '@redux/types/mapSlice';


export const useMapData = () => {

    const data = useSelector<RootState, IMapSlice>(({ map }) => map);

    return data;

}
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const mapApi = createApi({
    reducerPath: 'mapData',
    baseQuery: fetchBaseQuery({
        baseUrl: `${window.origin}/data`
    }),
    endpoints: (builder) => ({
        getData: builder.query<void, void>({
            query: () => '/easy-10M.r3.geojson'
        }),
        getOperations: builder.query<void, void>({
            query: () => '/test.json'
        })
    })
});

export const { useGetDataQuery, useGetOperationsQuery } = mapApi;

import { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { timeFormat } from 'd3-time-format';

import { getDataFromFeature } from '@utils/getDataFromFeature';

import { useMapActions } from '@hooks/useMapActions/useMapActions';
import { useMapData } from '@hooks/useMapData/useMapData';

import { useGetDataQuery } from '@redux/services/map';
import { IMapItem } from '@redux/types/mapSlice';

import { IUseMap } from './useMap.interfaces';
import { init, formatData, sortData } from './useMap.utils';
import { getColor } from '@hooks/useMapMethods/useMapMethods.utils';

import secrets from '@secrets';




export const useMap = ({ containerRef, onLoad, options }: IUseMap): any => {

    const mapRef = useRef<mapboxgl.Map | null>(null);
    const dataRef = useRef<any>(null);

    const { data, isSuccess } = useGetDataQuery();

    const { setSortedData, setLoad, setMapItems } = useMapActions();
    const { loaded, sortedData } = useMapData();



    
    const updateSortedData = (data: any) => {
        const f = sortData(data);
        setSortedData(f);
    }



    const applyData = () => {
        const f = formatData(data);
        dataRef.current = f;
    }



    const resizeMap = () => {
        mapRef.current?.resize();
    }
    


    const addMapItems = () => {


        const items: IMapItem[] = [...sortedData!.entries()].map(([p, features], index) => {
            const { volume } = getDataFromFeature(features);
            
            const fullDate = new Date(p as string);

            const formatHour = timeFormat('%I:%M %p');
            const formatDate = timeFormat('%B %d, %Y / %A');
    

            const hour = formatHour(fullDate);
            const date = formatDate(fullDate);

            const color = getColor(volume);

            return {
                date: {
                    date,
                    hour
                },
                color,
                volume,
                periodIndex: index
            }
    
        });
        
        setMapItems(items);

    }
 


    useEffect(() => {

        if (!isSuccess) { return; }

        applyData();

        init({
            mapRef,
            containerRef,
            options,
            token: secrets.mapboxToken!,
            onLoad: () => {
                updateSortedData(data);
                setLoad(true);
                onLoad && onLoad()
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);



    useEffect(() => {

        if (loaded && sortedData) {
            addMapItems();
            setLoad(true);
        }


        window.addEventListener('resize', resizeMap);
        
        return () => {
            window.removeEventListener('resize', resizeMap);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded, sortedData]);



    return {
        mapRef,
        dataRef
    };
}
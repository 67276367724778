import { ComponentType, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '@redux/store';

import { closeMenu } from '@redux/slices/universalSlice';


export function withRouteChange<P>(Component: ComponentType<P>) {
    const WrappedComponent = (props: P) => {


        const location = useLocation();
        const dispatch = useAppDispatch();
        

        useEffect(() => {
            dispatch(closeMenu());

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [location]);


        return (

            <Component {...props} />
        )
    }

    return WrappedComponent;
    
}
import { useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import { timeFormat } from 'd3-time-format';

import { useMapActions } from '@hooks/useMapActions/useMapActions';

import { markerHtml, getPoints, getLocation, getVolume, getCurrentPeriod, getColor } from './useMapMethods.utils';
import { getDataFromFeature } from '@utils/getDataFromFeature';

import { IPoints } from '@redux/types/mapSlice';
import { MapboxGlEvent, IUseMapMethods } from './useMapMethods.interfaces';


export const useMapMethods = ({ mapRef, sortedData, markerRef, data, timelineRef, animationRef }: Partial<IUseMapMethods>) => {


    const {
        setMarker,
        setPopup,
        setRangeValue,
        setAutoplay,
        setCurrent,
        setPoints,
        setPopupValues
    } = useMapActions();

    const createMarkerHTML = useCallback(() => {

        const element = document.createElement('div');
        element.classList.add('map__marker', 'js-map-marker');
        element.innerHTML = markerHtml;

        return element;

    }, []);


    /**
     * Add marker to mapbox
     * @param event - Mapbox MapMouse Event
     */
    const applyMarker = (event: MapboxGlEvent) => {

        if (markerRef!.current) {
            removeMarker();
        }

        updatePoints(event);

        const element = createMarkerHTML();

        const { lngLat } = event;

        markerRef!.current = new mapboxgl.Marker(element)
            .setLngLat(lngLat)
            .addTo(mapRef!.current!);

    };



    /**
     *  Remove marker from mapbox
     */
    const removeMarker = (fast?: boolean) => {

        if (markerRef!.current) {
            markerRef!.current.remove();
            markerRef!.current = null;

            setTimeout(() => {
                if (!markerRef!.current) {
                    setMarker(false);
                }
            }, fast ? 0 : 1000);
        }
    };


    /**
     * Create popup for existing marker
     * @param event - Mapbox MapMouse Event
     * @returns Promise
     */
    const applyPopup = async (event: MapboxGlEvent): Promise<void> => {

        const location = await getLocation(event);
        const volume = getVolume(event, mapRef!);
        const color = getColor(volume);

        setPopup({
            location,
            volume,
            color
        });
    };



    const updatePopupVolume = (points: IPoints) => {

        const bbox = [
            [points.x - 3, points.y - 3],
            [points.x + 3, points.y + 3]
        ];

        // Find features intersecting the bounding box.
        const selectedFeatures = mapRef!.current!.queryRenderedFeatures(bbox as [mapboxgl.PointLike, mapboxgl.PointLike], {
            layers: ['heatmap-layer']
        });

        const { volume } = getDataFromFeature(selectedFeatures);

        const color = getColor(volume);

        setPopupValues({
            volume,
            color
        });

    };



    /**
     * update current marker's points
     * @param event mapboxgl.MapMouseEvent
     */
    const updatePoints = (event: MapboxGlEvent) => {

        const points = getPoints(event);
        setPoints(points);

    };



    /**
     * Initialize starting point period
     */
    const applyCurrent = () => {
        updateCurrent(0);
    };


    /**
     * Update current volume
     * @param period
     */
    const getVolumeAndColor = (period: any) => {

        // eslint-disable-next-line
        const [date, features] = period;

        if (features && features.length !== 0) {
            const { volume } = getDataFromFeature(features);
            const color = updateColor(volume);

            return {
                volume,
                color
            };
        }

    };


    /**
     * Update current color
     * @param volume
     */
    const updateColor = (volume: any) => {

        const color = getColor(volume);
        return color;

    };


    /**
     * Update current item
     * @param index
     * @returns
     */
    const updateCurrent = (index: number) => {

        const period = getCurrentPeriod(index, sortedData);

        if (period.length === 0) { return; }

        const periodIndex = getPeriodIndex(period) ?? 0;
        const { volume, color } = getVolumeAndColor(period) ?? { volume: 1000, color: 'red' };
        const date = getDate(period);

        setCurrent({
            period,
            volume,
            color,
            date,
            periodIndex
        });

    };


    const getPeriodIndex = (period: any) => {

        const currentPeriodDate = period[0];
        const index = [...sortedData.entries()].findIndex(el => el[0] === currentPeriodDate);
        return index;

    };

    /**
     * Update heatmap timeline
     * @param object
     */
    const applyTimeline = () => {

        timelineRef!.current.fromTo(animationRef!.current, {
            value: 0,
        }, {
            value: sortedData.size,
            roundProps: {
                value: 1,
            },
            duration: 4,
            ease: 'none',
            onUpdate: () => {
                const value = animationRef!.current?.value ?? 0;
                updateCurrent(value);
                setRangeValue(value);
            },
        });

    };


    /**
     * Attach click event to mapbox
     * @param event - Mapbox MapMouse Event
     */
    const applyClick = async (event: MapboxGlEvent) => {

        setAutoplay(false);
        applyMarker(event);
        await applyPopup(event);

        setMarker(true);
    };


    /**
     * Create heatmap from data
     */
    const applyHeatmap = () => {

        const formatedFeatures = (data as GeoJSON.FeatureCollection).features.map(f => {
            const featureFormatted = f;
            if (f.properties!.fill === '#FF0000') { featureFormatted.properties!.fill = '#E84646'; }
            if (f.properties!.fill === '#FF9900') { featureFormatted.properties!.fill = '#FB8D14'; }
            if (f.properties!.fill === '#FFFF00') { featureFormatted.properties!.fill = '#BAB8B8'; }
            if (f.properties!.fill === '#CCFF00') { featureFormatted.properties!.fill = '#FBCA14'; }
            if (f.properties!.fill === '#009900') { featureFormatted.properties!.fill = '#92C228'; }
            if (f.properties!.fill === '#00FF66') { featureFormatted.properties!.fill = '#BBDB77'; }

            return featureFormatted;
        });

        const featureCollection: GeoJSON.FeatureCollection = { type: "FeatureCollection", "features": formatedFeatures };

        mapRef!.current?.addSource('heatmap', {
            type: 'geojson',
            data: featureCollection,
        });

        mapRef!.current?.addLayer({
            id: 'heatmap-layer',
            source: 'heatmap',
            type: 'fill',
            paint: {
                'fill-color': ['case', ['has', 'fill'], ['get', 'fill'], 'rgba(0, 0, 0, 0)'],
            },
        });
    };


    /**
     *  Remove heatmap from map
     */
    const removeHeatmap = () => {

        const layerName = 'heatmap-layer';
        const sourceName = 'heatmap';

        const source = mapRef!.current?.getSource(sourceName);
        const layer = mapRef!.current?.getLayer(layerName);

        if (source && layer) {
            mapRef!.current?.removeLayer(layerName);
            mapRef!.current?.removeSource(sourceName);
        }

    };


    /**
     * Update heatmap with period's change
     * @param period - current period
     */
    const updateHeatmap = (period: any) => {

        if (period.length === 0) { return; }

        const begin: string = period[1][0].properties.timespan.begin;

        if (!begin) {
            console.log('There is no begin in period');
            return;
        }

        const layer = mapRef!.current?.getLayer('heatmap-layer');

        layer && mapRef!.current?.setFilter('heatmap-layer', [
            'match',
            ['get', 'begin'],
            begin,
            true,
            false
        ]);

    };



    /**
     *
     * @param period
     */
    const getDate = (period: any) => {

        const fullDate = new Date(period[0] as string);

        const formatHour = timeFormat('%I:%M %p');
        const formatDate = timeFormat('%B %d, %Y / %A');

        const hour = formatHour(fullDate);
        const date = formatDate(fullDate);

        return {
            hour,
            date
        };

    };



    /**
     * Create autoplay for heatmap
     * @param object
     */
    const applyAutoplay = ({ autoplay, rangeValue }: any) => {

        autoplay
            ? timelineRef!.current.progress(rangeValue / sortedData!.size).play()
            : timelineRef!.current.pause();

    };



    return {
        applyMarker,
        applyPopup,
        applyCurrent,
        applyClick,
        applyTimeline,
        applyHeatmap,
        applyAutoplay,
        removeHeatmap,
        removeMarker,
        updateCurrent,
        updateHeatmap,
        updatePopupVolume
    };
};
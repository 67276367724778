import { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { gsap } from 'gsap';

import { useUniversalData } from '@hooks/useUniversalData/useUniversalData';

import Image from '@components/universal/Image/Image';

import { routes } from './Menu.data';

import GreengouseImage from '@images/Greenhouse_George.png';


const Menu = () => {

    const menuRef = useRef<HTMLDivElement>(null);
    const menuHeightRef = useRef<number>(0);

    const { isMenuOpen } = useUniversalData();


    useEffect(() => {

        const menu = menuRef.current;
        const menuHeight = menu!.getBoundingClientRect().height;
        menuHeightRef.current = menuHeight;

        gsap.set(menu, { y: menuHeight });
        
    }, []);


    useEffect(() => {

        const menuHeight = menuHeightRef.current;

        if (menuHeight === 0) { return; } 

        const menu = menuRef.current;
        const body = document.body;
        const content = document.getElementById('main');
        const map = document.getElementById('nuclear-map');



        const yMenu = isMenuOpen ? 0 : -menuHeight;
        const yContent = isMenuOpen ? menuHeight : 0;

        isMenuOpen ? body.classList.add('is-menu-open') : body.classList.remove('is-menu-open');

        const params = {
            duration: 0.7, 
            ease: 'power2'
        }

        gsap.killTweensOf([menu, content]);
        
        gsap.to(menu, {
                ...params,
                y: yMenu,
                onStart: () => body.classList.add('is-menu-animating'),
                onComplete: () => body.classList.remove('is-menu-animating')
            });
        
        gsap.to([content, map], { ...params, y: yContent });
        

    }, [isMenuOpen]);


    return (
        <div className='menu' ref={menuRef}>
            <div className='menu__bg'>
                <Image source={GreengouseImage} />
            </div>
            <div className="menu__inner">
                <nav>
                    <ul className='menu__list'>
                        {routes.map(({ pathname, name }, index) => (
                            <li key={name}>
                                <NavLink to={pathname} className={({ isActive }) => isActive ? 'is-active' : ''}><span><sup>0{index}.</sup>{name}</span></NavLink>
                            </li>
                        ))}
                    </ul>
                </nav>
            
                {/*<div className='menu__footnote'>Produced by Earthrise Media</div>*/}
            </div>
        </div>
    );
}

export default Menu;

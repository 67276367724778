import { IImage } from './Image.interfaces';

const Image = ({ source, desktop, tablet, mobile, alt }: IImage) => {
    return (
        <picture className='image'>
            {mobile && <source media="(max-width:660px)" type='image/jpg' srcSet={mobile} />}
            {tablet && <source media="(max-width:1023px)" type='image/jpg' srcSet={tablet} />}
            {desktop && <source media="(min-width: 1024px)" type='image/jpg' srcSet={desktop} />}

            <img src={source ?? 'data:image/png;base64,aHVuY3dvdA=='} alt={alt ?? ''} />
        </picture>
    );
}

export default Image;